<template>
  <div>
    <div class="category">
      <div class="category-label">
        <span>Ramen linker zijde</span>
      </div>
      <div class="grid grid-cols-2 gap-2 option-group">
        <label
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.windows.left.length === 0 }">
          <IconWindowsNone />
          <span>Geen raam</span>
          <input type="radio" :value="[]" v-model="pod.options.windows.left" class="hidden"
            :disabled="!leftAvailable" />
        </label>

        <label v-if="leftAvailable && maxWindows >= 1"
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.windows.left.length === 1 }">
          <IconWindowsOne />
          <span>Eén raam</span>
          <input type="radio" :value="[0]" v-model="pod.options.windows.left" class="hidden" />
        </label>

        <label v-if="leftAvailable && maxWindows >= 2"
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.windows.left.length === 2 }">
          <IconWindowsTwo />
          <span>Twee ramen</span>
          <input type="radio" :value="[0, 0]" v-model="pod.options.windows.left" class="hidden" />
        </label>

        <label v-if="leftAvailable && maxWindows >= 3"
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.windows.left.length === 3 }">
          <IconWindowsThree />
          <span>Drie ramen</span>
          <input type="radio" :value="[0, 0, 0]" v-model="pod.options.windows.left" class="hidden" />
        </label>

        <label v-if="leftAvailable && maxWindows >= 4"
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.windows.left.length === 4 }">
          <IconWindowsFour />
          <span>Vier ramen</span>
          <input type="radio" :value="[0, 0, 0, 0]" v-model="pod.options.windows.left" class="hidden" />
        </label>
      </div>
    </div>

    <div class="category" v-if="pod.options.windows.left?.length > 0">
      <div class="category-label">
        <span>Kies de draai- en kiepfunctionaliteit</span>
      </div>
      <div class="grid gap-2 option-group">
        <div v-for="(w, idx) in pod.options.windows.left" :key="idx" class="flex items-center w-full gap-4 lg:gap-6">
          <span class="shrink-0">Raam {{ idx + 1 }}</span>
          <div class="px-[6px] py-[7px] rounded bg-brand-tint-orange text-black h-fit flex items-center w-full gap-1">
            <button class="px-3 py-[5px] rounded transition-all pointer-events-auto w-1/2"
              @click="setWindowType('left', idx, 0)" :class="{ 'bg-white text-brand-primary shadow': w === 0 }">
              vast
            </button>
            <button @click="setWindowType('left', idx, 1)"
              class="px-3 py-[5px] rounded transition-all pointer-events-auto w-1/2"
              :class="{ 'bg-white text-brand-primary shadow': w === 1 }">
              draaikiep
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="border-t category">
      <div class="category-label">
        <span>Ramen rechter zijde</span>
      </div>
      <div class="grid grid-cols-2 gap-2 option-group">
        <label
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.windows.right.length === 0 }">
          <IconWindowsNone />
          <span>Geen raam</span>
          <input type="radio" :value="[]" v-model="pod.options.windows.right" class="hidden"
            :disabled="!rightAvailable" />
        </label>

        <label v-if="rightAvailable && maxWindows >= 1"
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.windows.right.length === 1 }">
          <IconWindowsOne />
          <span>Eén raam</span>
          <input type="radio" :value="[0]" v-model="pod.options.windows.right" class="hidden" />
        </label>

        <label v-if="rightAvailable && maxWindows >= 2"
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.windows.right.length === 2 }">
          <IconWindowsTwo />
          <span>Twee ramen</span>
          <input type="radio" :value="[0, 0]" v-model="pod.options.windows.right" class="hidden" />
        </label>

        <label v-if="rightAvailable && maxWindows >= 3"
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.windows.right.length === 3 }">
          <IconWindowsThree />
          <span>Drie ramen</span>
          <input type="radio" :value="[0, 0, 0]" v-model="pod.options.windows.right" class="hidden" />
        </label>

        <label v-if="rightAvailable && maxWindows >= 4"
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.windows.right.length === 4 }">
          <IconWindowsFour />
          <span>Vier ramen</span>
          <input type="radio" :value="[0, 0, 0, 0]" v-model="pod.options.windows.right" class="hidden" />
        </label>
      </div>
    </div>
    <div class="category" v-if="pod.options.windows.right?.length > 0">
      <div class="category-label">
        <span>Kies de draai- en kiepfunctionaliteit</span>
      </div>
      <div class="grid gap-2 option-group">
        <div v-for="(w, idx) in pod.options.windows.right" :key="idx" class="flex items-center w-full gap-4 lg:gap-6">
          <span class="shrink-0">Raam {{ idx + 1 }}</span>
          <div class="px-[6px] py-[7px] rounded bg-brand-tint-orange text-black h-fit flex items-center w-full gap-1">
            <button class="px-3 py-[5px] rounded transition-all pointer-events-auto w-1/2"
              @click="setWindowType('right', idx, 0)" :class="{ 'bg-white text-brand-primary shadow': w === 0 }">
              vast
            </button>
            <button @click="setWindowType('right', idx, 1)"
              class="px-3 py-[5px] rounded transition-all pointer-events-auto w-1/2"
              :class="{ 'bg-white text-brand-primary shadow': w === 1 }">
              draaikiep
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Dakraam -->
    <div class="border-t category" :class="{ 'error': pod.errors.includes('dakraam') }">
      <div class="category-label">
        <span>Dakraam</span>
        <ToolTip @tooltip-clicked="dakraamTooltip()" />
      </div>
      <div class="grid grid-cols-2 gap-2 option-group">
        <label
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.dakraam === 0 }">
          <IconDakraamNone />
          <span>Geen dakraam</span>
          <input type="radio" :value="0" v-model="pod.options.dakraam" class="hidden" />
        </label>

        <label v-if="maxDakramen >= 1"
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.dakraam === 1 }">
          <IconDakraamOne />
          <span> {{ (maxDakramen < 2) ? 'Dakraam' : 'Eén dakraam' }}</span>
              <input type="radio" :value="1" v-model="pod.options.dakraam" class="hidden" />
        </label>

        <label v-if="maxDakramen >= 2"
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.dakraam === 2 }">
          <IconDakraamTwo />
          <span>Twee dakramen</span>
          <input type="radio" :value="2" v-model="pod.options.dakraam" class="hidden" />
        </label>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { usePodStore } from '@/stores/pod'
import { useOptions } from '@/composables/useOptions'
import { useModal } from '@/composables/useModal'
import { useModx } from '@/composables/useModx'

import IconWindowsNone from '@/components/Icons/IconWindowsNone.vue'
import IconWindowsOne from '@/components/Icons/IconWindowsOne.vue'
import IconWindowsTwo from '@/components/Icons/IconWindowsTwo.vue'
import IconWindowsThree from '@/components/Icons/IconWindowsThree.vue'
import IconWindowsFour from '@/components/Icons/IconWindowsFour.vue'

import IconDakraamNone from '@/components/Icons/IconDakraamNone.vue'
import IconDakraamOne from '@/components/Icons/IconDakraamOne.vue'
import IconDakraamTwo from '@/components/Icons/IconDakraamTwo.vue'

import ToolTip from '@/components/ToolTip.vue'

import { ref, computed, watch } from 'vue'
import { useForceRerender } from '@/composables/useForceRerender'
import { useGtm } from '@/composables/useGtm'

const pod = usePodStore()

const modx = useModx()

const maxWindows = ref(0)

const maxDakramen = ref(0)

const availableWindows = ref(<{ hs_id: number; optie: string; }[] | null>null)

useOptions().then((optionStore) => {
  const model = optionStore.getModel(pod.options)
  if (model === null) return
  maxWindows.value = Number(model.aantal_ramen)
  maxDakramen.value = Number(model.max_aantal_dakramen)
  availableWindows.value = optionStore.getAvailableWindows(pod.options)
})

const leftAvailable = computed(() => {
  if (pod.options.berging === false || pod.options.podtype === 'alpha') {
    return true
  }

  // Check the orientation of the pod
  if (pod.options.orientation === 'links_hoog') {
    return true
  }

  if (pod.options.orientation === 'plat_dak' && pod.options.storage_mirrored) {
    return true
  }

  resetWindows('left')

  return false
})

const rightAvailable = computed(() => {
  if (pod.options.berging === false || pod.options.podtype === 'alpha') {
    return true
  }

  // Check the orientation of the pod
  if (pod.options.orientation === 'rechts_hoog') {
    return true
  }

  if (pod.options.orientation === 'plat_dak' && pod.options.storage_mirrored === false) {
    return true
  }

  //Remove the right windows
  resetWindows('right')

  return false
})

function resetWindows(side: 'left' | 'right') {
  pod.options.windows[side] = []
}

function setWindowType(side: 'left' | 'right', index: number, type: 0 | 1) {
  pod.options.windows[side][index] = type
  useForceRerender({ 'windows': pod.renderOptions().windows })
}

// function setWindows(side: 'left' | 'right', count: number) {
//   if (count === 0) {
//     pod.options.windows[side] = []
//   } else {
//     pod.options.windows[side] = Array(count).fill(false)
//   }
// }

async function dakraamTooltip() {
  useModal((await modx).content['dakraam'].title, (await modx).content['dakraam'].content)
}

watch(
  () => pod.options.dakraam,
  () => {
    if (pod.errors.includes('dakraam')) {
      pod.errors = []
    }
  }
)

useGtm().addEvent('configurator-stap-ramen')
</script>
