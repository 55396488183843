import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { ConfiguratorOptions, ConfiguratorRenderOptions } from '@/types/configurator'
import { useOptions } from '@/composables/useOptions'
import type { ConfigurationGetObject, ConfigurationPostObject } from '@/types/modx'

export const usePodStore = defineStore('pod', () => {
  const started = ref(false)
  const showConfirmation = ref(false)

  const showHelp = ref(false)

  const hash = ref(
    Math.random().toString(36).substring(2) +
      Date.now().toString(36) +
      Math.random().toString(36).substring(2)
  )

  function resetHash() {
    hash.value =
      Math.random().toString(36).substring(2) +
      Date.now().toString(36) +
      Math.random().toString(36).substring(2)
  }
  const price = ref('0')
  const currentStep = ref(0)

  const optInForm = ref({
    firstName: '',
    lastName: '',
    email: '',
    loading: false
  })

  const quoteForm = ref({
    voornaam: '',
    achternaam: '',
    email: '',
    bedrijf: '',
    straatnaam: '',
    huisnummer: '',
    postcode: '',
    plaatsnaam: '',
    landcode: '',
    telefoon: ''
  })

  const additionalInfo = ref({
    lat: 0,
    lng: 0,
    purpose: []
  })

  const options = ref(<ConfiguratorOptions>{
    podtype: null,
    width: 4,
    depth: 3,
    berging: null,
    storage_mirrored: true,
    orientation: null,
    cladding_type: 'normaal',
    door_type: null,
    dark_hinges: false,
    door_mirrored: null,
    windows: {
      left: [],
      right: []
    },
    dakraam: null,
    distance: '',
    foundation: null,
    climate_control: false,
    floor_heating: false,
    sedum: false,
    casco: false,
    dark_cladding: false,
    planken: null,
    optie_keuken: false,
    optie_toilet: false,
    optie_sanitair_casco: false,
    optie_badkamer: false,
    plaatsing_badkamer: 'hoek',
    comment: ''
  })

  const steps = [
    'Modelkeuze',
    'Afmetingen',
    'Vormgeving',
    'Deuren',
    'Ramen',
    'Opties',
    'Transport',
    'Fundering',
    'Opmerkingen',
    'Samenvatting'
  ]

  const errors = ref(<string[]>[])

  function renderOptions(): ConfiguratorRenderOptions {
    return {
      berging: options.value.berging ?? false,
      casco: options.value.casco,
      cladding_type:
        options.value.cladding_type === 'zwart' ? 'normaal' : options.value.cladding_type,
      climate_control: options.value.climate_control,
      dakraam: options.value.dakraam ?? 0,
      dark_cladding: options.value.cladding_type === 'zwart', //options.value.dark_cladding,
      dark_glass: false,
      depth: options.value.podtype !== 'alpha' ? options.value.depth : options.value.width,
      door_mirrored: options.value.door_mirrored ?? false,
      door_type: options.value.door_type ?? 'enkeldraai',
      dark_hinges: options.value.dark_hinges ?? false,
      orientation: options.value.orientation,
      planken: options.value.planken ?? getPlanken(),
      podtype: options.value.podtype ?? 'beta',
      pv_panelen: false,
      sedum: options.value.sedum,
      storage_mirrored: options.value.storage_mirrored,
      vloerverwarming: options.value.floor_heating,
      white_ceiling: false,
      width: options.value.podtype !== 'alpha' ? options.value.width : options.value.depth,
      windows: options.value.windows
    }
  }

  function getPlanken(kort_voorportaal: boolean | null = null): 2 | 3 | 5 {
    if (options.value.podtype === 'alpha') {
      if (options.value.width < 5 || (kort_voorportaal !== null && kort_voorportaal)) {
        return 3
      }
      return 5
    }

    if (options.value.width < 4 || (kort_voorportaal !== null && kort_voorportaal)) {
      return 2
    }

    return 3
  }

  async function calculatePrice() {
    const data = await useOptions()

    const cPrice = data.calculatePrice(options.value)

    if (cPrice === 0) {
      price.value = '0'
      return
    }

    price.value = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(cPrice)
  }

  function setStep(step: number) {
    // Validate step
    if (!stepIsValid(step)) {
      return
    }

    currentStep.value = step
    scrollProgress()
  }

  function nextStep() {
    setStep(currentStep.value + 1)

    storeConfiguration()
  }

  function prevStep() {
    if (currentStep.value === 0) return
    setStep(currentStep.value - 1)
  }

  async function nextTick() {
    return new Promise((resolve) => {
      setTimeout(resolve, 0)
    })
  }

  async function scrollProgress() {
    await nextTick()

    const progress = document.querySelector('.step-indicator.selected')

    if (!progress) return

    progress.scrollIntoView({
      behavior: 'smooth',
      inline: 'center'
    })
  }

  function stepIsValid(step: number): boolean {
    // Allow if the next step is lower or equal to the current step
    if (step <= currentStep.value) {
      return true
    }

    // Reset errors
    errors.value = []

    // Validate the current steps and all steps in between
    for (let i = currentStep.value; i < step; i++) {
      if (!validateStep(i)) {
        return false
      }
    }

    return true
  }

  function validateStep(step: number): boolean {
    switch (step) {
      case 0: // Model
        if (options.value.podtype === null) {
          errors.value.push('model')
          return false
        }
        break
      case 2: // Orientation
        if (options.value.orientation === null) {
          errors.value.push('orientation')
        }

        if (options.value.berging === null) {
          errors.value.push('berging')
        }

        if (errors.value.length > 0) {
          return false
        }

        break
      case 3: // Doors
        if (options.value.door_type === null) {
          errors.value.push('door')
        }

        if (options.value.door_mirrored === null) {
          errors.value.push('door_mirrored')
        }

        if (errors.value.length > 0) {
          return false
        }

        break
      case 4: // Dakraam
        if (options.value.dakraam === null) {
          errors.value.push('dakraam')
          return false
        }
        break
      case 6: // Transport
        if (options.value.distance === '') {
          errors.value.push('transport')
          return false
        }
        break
      case 7: // Foundation
        if (options.value.foundation === null) {
          errors.value.push('foundation')
          return false
        }
        break
    }

    return true
  }

  async function getHsProducten(): Promise<Record<string, {} | { id: number; aantal: number }>> {
    const data = await useOptions()

    const products = data.getHsProducts(options.value)

    return products
  }

  async function getCascoDiscount(): Promise<number> {
    const data = await useOptions()

    if (options.value.casco === false) {
      return 0
    }

    // Todo calculate casco discount
    return data.calculatePriceCasco(options.value) ?? 0
  }

  async function storeConfiguration() {
    if (options.value.podtype === null) {
      return
    }

    // Force update price
    calculatePrice()

    const hsProducten = await getHsProducten()

    const cascoDiscount = await getCascoDiscount()

    const configurationData: ConfigurationPostObject = {
      pod_hash: hash.value,
      modeltype: options.value.podtype,
      berging: options.value.berging ?? false,
      orientatie: options.value.orientation ?? 'links_hoog',
      cladding_type: options.value?.cladding_type ?? 'normaal',
      dak_type: options.value.orientation === 'plat_dak' ? 'P' : 'S',
      dark_hinges: options.value.dark_hinges,
      type_deur: getModxDoorName(options.value.door_type),
      locatie_deurklink: options.value.door_mirrored ? 'rechtse_deur' : 'linkse_deur',
      locatie_bergingsdeur: options.value.storage_mirrored ? 'rechtse_deur' : 'linkse_deur',
      type_ramen_links: options.value.windows.left.map((x) => String(x)),
      type_ramen_rechts: options.value.windows.right.map((x) => String(x)),
      transport: options.value.distance,
      opmerkingen: options.value.comment,
      pod_breedte: options.value.podtype === 'beta' ? options.value.width : options.value.depth,
      pod_diepte: options.value.podtype === 'beta' ? options.value.depth : options.value.width,
      dakraam: options.value.dakraam ?? 0,
      pod_planken: options.value.planken ?? getPlanken(),
      sedumdak: options.value.sedum,
      verkort_portaal: options.value.planken !== null && options.value.planken !== getPlanken(),
      climate_control: options.value.climate_control,
      vloerverwarming: options.value.floor_heating,
      casco: options.value.casco,
      gevelbekleding: options.value.dark_cladding,
      keuken: options.value.optie_keuken,
      toilet: options.value.optie_toilet,
      badkamer: options.value.optie_badkamer,
      plaatsing_badkamer: options.value.plaatsing_badkamer,
      optie_sanitair_casco: options.value.optie_sanitair_casco,
      plafond: false,
      fundering: options.value.foundation ?? false,
      aan_de_slag: {
        voornaam: optInForm.value.firstName,
        achternaam: optInForm.value.lastName,
        emailadres: optInForm.value.email,
        privacy: true
      },
      offerte_form: quoteForm.value,
      additional_info: additionalInfo.value,
      hubspot_ids: hsProducten,
      hubspot_prijzen: {
        korting: {
          casco: cascoDiscount
        }
      },
      prijzen: {
        totaalprijs: parseInt(price.value.replace(/\D/g, ''))
      },
      zonwerend_glas: false,
      ergonomisch_bureau: false,
      ergonomisch_stoel: false,
      pv_panelen: false
    }

    try {
      const res = await fetch(import.meta.env.VITE_API_URL + '/rest/configuration', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json'
        },
        body: JSON.stringify(configurationData)
      })

      if (!res.ok) {
        console.error('Failed to store configuration')
        return
      }
    } catch (e) {
      console.error(e)
      return
    }
  }

  function getModxDoorName(
    door: ConfiguratorOptions['door_type']
  ): ConfigurationGetObject['type_deur'] {
    switch (door) {
      case 'enkeldraai':
        return 'enkele_draaideur'
      case 'dubbeldraai':
        return 'dubbele_draaideur'
      case 'enkelschuif':
        return 'enkele_schuifpui'
      case 'dubbelschuif':
        return 'dubbele_schuifpui'
      default:
        return 'enkele_draaideur'
    }
  }

  function getConfigurationDoorName(
    door: ConfigurationGetObject['type_deur']
  ): ConfiguratorOptions['door_type'] {
    switch (door) {
      case 'enkele_draaideur':
        return 'enkeldraai'
      case 'dubbele_draaideur':
        return 'dubbeldraai'
      case 'enkele_schuifpui':
        return 'enkelschuif'
      case 'dubbele_schuifpui':
        return 'dubbelschuif'
      default:
        return 'enkeldraai'
    }
  }

  async function resumeHash(h: string) {
    try {
      const res = await fetch(import.meta.env.VITE_API_URL + '/rest/configuration/?hash=' + h)

      if (!res.ok) {
        console.error('Failed to fetch configuration')
        return
      }

      const data: { success: boolean; message: ConfigurationGetObject } = await res.json()

      if (!data.success) {
        console.error('Failed to find configuration')
        return
      }

      loadConfiguration(data.message)
    } catch (e) {
      console.error(e)
      return
    }
  }

  async function previewHash(h: string): Promise<boolean> {
    try {
      const res = await fetch(
        import.meta.env.VITE_API_URL + '/rest/configuration/?preview=true&hash=' + h
      )

      if (!res.ok) {
        console.error('Failed to fetch configuration')
        return false
      }

      const data: { success: boolean; message: ConfigurationGetObject } = await res.json()

      if (!data.success) {
        console.error('Failed to find configuration')
        return false
      }

      loadConfiguration(data.message)
    } catch (e) {
      console.error(e)
      return false
    }

    return true
  }

  function loadConfiguration(config: ConfigurationGetObject) {
    hash.value = config.pod_hash
    options.value.width = config.modeltype === 'beta' ? config.pod_breedte : config.pod_diepte
    options.value.depth = config.modeltype === 'beta' ? config.pod_diepte : config.pod_breedte

    options.value.berging = Boolean(config.berging)
    options.value.storage_mirrored = config.locatie_bergingsdeur === 'rechtse_deur'

    options.value.orientation = config.orientatie

    options.value.cladding_type = config?.cladding_type ?? 'normaal'

    options.value.door_type = getConfigurationDoorName(config.type_deur ?? 'enkele_draaideur')

    options.value.dark_hinges = config?.dark_hinges ?? false

    options.value.door_mirrored = config.locatie_deurklink === 'rechtse_deur'

    options.value.dakraam = Number(config.dakraam)

    options.value.distance = config.transport !== false ? config.transport : ''
    options.value.comment = config.opmerkingen
    options.value.sedum = Boolean(config.sedumdak)

    options.value.optie_toilet = Boolean(config.toilet)
    options.value.optie_badkamer = Boolean(config.badkamer)
    options.value.optie_keuken = Boolean(config.keuken)
    options.value.plaatsing_badkamer = config.plaatsing_badkamer ?? 'hoek'
    options.value.optie_sanitair_casco = Boolean(config.optie_sanitair_casco ?? false)

    options.value.casco = Boolean(config.casco)
    options.value.kort_voorportaal = Boolean(config.verkort_portaal)

    options.value.climate_control = Boolean(config.climate_control)
    options.value.floor_heating = Boolean(config.vloerverwarming)
    options.value.dark_cladding = Boolean(config.gevelbekleding)

    options.value.foundation = Boolean(config.fundering)

    // Set podtype last to prevent early renders
    options.value.podtype = config.modeltype

    setTimeout(() => {
      // Check if pod_planken exists
      if (typeof config.pod_planken !== 'undefined') {
        options.value.planken = config.pod_planken
      } else {
        options.value.planken = getPlanken(config.verkort_portaal)
      }

      // Set windows last bc of reset when podtype changes
      options.value.windows.left = config.type_ramen_links.map((x) => parseInt(x))
      options.value.windows.right = config.type_ramen_rechts.map((x) => parseInt(x))
    }, 100)
  }

  return {
    started,
    showConfirmation,
    showHelp,
    hash,
    resetHash,
    price,
    currentStep,
    optInForm,
    quoteForm,
    additionalInfo,
    options,
    steps,
    errors,
    renderOptions,
    getPlanken,
    calculatePrice,
    setStep,
    nextStep,
    prevStep,
    storeConfiguration,
    resumeHash,
    previewHash
  }
})
